@font-face {
    font-family: 'Aeonik-Light';
    src: url('fonts/Aeonik-Light.ttf');
}

@font-face {
    font-family: 'Aeonik';
    src: url('fonts/Aeonik-Regular.ttf');
}

html, body {
    margin: 0
}

body {
    font-weight: 400;
    font-family: 'Aeonik';
}
